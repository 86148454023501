<template>
  <div class="mystake">
    <h5header :name="$t('stake.desc14')" :right="false" />
    <div class="mystake-top animate__animated animate__fadeInUp">
      <div class="top-info">
        <div class="mt-cen">
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("stake.desc22") }}(<span>DAOT</span>)</div>
            <div class="mt-b">{{ incomeTodoInfo.stakeRewards||0 }} </div>
          </div>
          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("stake.desc23") }}(<span>DAOT</span>)</div>
            <div class="mt-b">{{ incomeTodoInfo.generativeRewards||0 }}</div>
          </div>

          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("stake.desc24") }}(<span>DAOT</span>)</div>
            <div class="mt-b">{{ incomeTodoInfo.referralRewards||0 }}</div>
          </div>

          <div class="mt-cen-item">
            <div class="mt-t">{{ $t("stake.desc25") }}(<span>DAOT</span>)</div>
            <div class="mt-b">{{ incomeTodoInfo.levelMatchRewards||0 }}</div>
          </div>
          <div class="mt-bt" :class="{ on: todoAmountTotal<= 0 }" @click="handelReview">
            {{ $t("myincome.desc5") }}
          </div>
        </div>
        <div class="mt-bt" :class="{ on: todoAmountTotal <= 0 }" @click="handelReview">
          {{ $t("myincome.desc5") }}
        </div>
      </div>
      <div class="h5top-name flex">
        <div>{{ $t("market.desc124") }}</div>
        <p class="flex" @click="showOverview=!showOverview">
          <span v-if="!showOverview">{{ $t("market.desc132") }}</span><span v-else>{{ $t("planet.desc65") }}</span>
          <img src="../../public/images/new/upicon.png" :class="{ img1: showOverview }" />
        </p>
      </div>
      <div class="mt-bot" v-if="showOverview">
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc26") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfo.totalStaked)}}</div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc27") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfoAmountTotal)}}</div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc28") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfo.totalRewards)}}</div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc29") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfo.totalGenerativeRewards)}}</div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc30") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfo.totalReferralRewards) }}</div>
        </div>
        <div class="mt-bot-item">
          <div class="mt-t">{{ $t("stake.desc31") }}(<span>DAOT</span>)</div>
          <div class="mt-b">{{ common.cutXiaoNum1(statisticsInfo.totalLevelMatchRewards) }}</div>
        </div>
      </div>
    </div>
    <div class="mystake-ex animate__animated animate__fadeInUp animate__delay-.2s">
      <div class="me-item" :class="{ on: tabIndex == 0 }" @click="exChangeTab(0)">
        {{ $t("stake.desc32") }}
      </div>
      <div class="me-item" :class="{ on: tabIndex == 1 }" @click="exChangeTab(1)">
        {{ $t("stake.desc33") }}
      </div>
      <div class="me-item" :class="{ on: tabIndex == 2 }" @click="exChangeTab(2)">
        {{ $t("stake.desc34") }}
      </div>
    </div>
    <div class="mystake-select flex animate__animated animate__fadeInUp animate__delay-.4s">
      <div class="select-time flex">
        <!-- 日期 -->
        <el-date-picker  @change="dateSelect" v-model="value1" type="daterange" :range-separator="$t('record.desc2')"
          :start-placeholder="$t('record.desc1')" :end-placeholder="$t('record.desc3')">
        </el-date-picker>
        <!-- 状态 -->
        <el-select v-if="tabIndex==0" v-model="buyBackState" :placeholder="$t('stake.desc35')">
          <el-option :value="''" :label="$t('stake.desc35')"></el-option>
          <el-option :value="1" :label="$t('stake.desc36')"></el-option>
          <el-option :value="2" :label="$t('stake.desc37')"></el-option>
        </el-select>
        <!-- 状态 -->
        <el-select  style="margin-left: 0px;" v-if="tabIndex==1" v-model="incomeStatus" :placeholder="$t('stake.desc35')">
          <el-option :value="''" :label="$t('stake.desc35')"></el-option>
          <el-option :value="0" :label="$t('stake.desc38')"></el-option>
          <el-option :value="1" :label="$t('stake.desc39')"></el-option>
        </el-select>
        <!-- 类型 -->
        <el-select v-if="tabIndex==1" v-model="incomeType" :placeholder="$t('stake.desc40')">
          <el-option :value="''" :label="$t('stake.desc40')"></el-option>
          <el-option :value="1" :label="$t('stake.desc41')"></el-option>
          <el-option :value="2" :label="$t('stake.desc42')"></el-option>
          <el-option :value="3" :label="$t('stake.desc43')"></el-option>
          <el-option :value="4" :label="$t('stake.desc44')"></el-option>
        </el-select>
        <!-- 产品 -->
        <el-select v-if="tabIndex!=1" v-model="productId" :placeholder="$t('stake.desc45')">
          <el-option :value="''" :label="$t('stake.desc45')"></el-option>
          <el-option :value="1" :label="'L1'"></el-option>
          <el-option :value="2" :label="'L2'"></el-option>
          <el-option :value="3" :label="'L3'"></el-option>
          <el-option :value="4" :label="'L4'"></el-option>
        </el-select>
      </div>
      <div class="select-btn flex">
        <div class="flexcenter" @click="handlerReset">
          {{ $t("asset.desc87") }}
        </div>
        <div class="flexcenter" @click="handlerSearch">
          {{ $t("asset.desc88") }}
        </div>
      </div>
    </div>
    <div class="mystake-table animate__animated animate__fadeInUp animate__delay-.6s">
      <div v-if="tabIndex==0">
        <div class="table-thead flex">
          <div>{{ $t('stake.desc46') }}</div>
          <div>{{ $t('stake.desc47') }}</div>
          <div>{{ $t('stake.desc48') }}</div>
          <div>{{ $t('stake.desc49') }}</div>
          <div>{{ $t('stake.desc50') }}</div>
          <div>{{ $t('stake.desc51') }}</div>
          <div>{{ $t('stake.desc52') }}</div>
          <div>{{ $t('stake.desc53') }}</div>
        </div>
        <div class="table-tbody">
          <div class="tbody-list flex" v-for="item in list" :key="item.id">
            <div class="flexcenter">{{ item.effectiveTime||'--' }}</div>
            <div class="flexcenter">{{ item.createTime }}</div>
            <div class="flexcenter">{{ item.productName }}</div>
            <div class="flexcenter">{{ item.maturityTime }}</div>
            <div class="flexcenter">{{ item.investAmount }} {{item.payCoinName}}</div>
            <div class="flexcenter">{{ item.stakedAmount }} USDT</div>
            <div class="flexcenter">{{ item.buyBackState==1?$t('stake.desc36'):$t('stake.desc37') }}</div>
            <!-- 操作 -->
            <div class="flex flexcenter" :class="item.buyBackState==1?'green':''" @click="item.buyBackState==1?handlerInfo(item,1):''">{{ $t('stake.desc54') }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="tabIndex==1">
        <div class="table-thead flex">
          <div>{{$t("stake.desc55")}}</div>
          <div>{{$t("stake.desc56")}}</div>
          <div>{{$t("stake.desc57")}}</div>
          <div>{{$t("stake.desc58")}}</div>
          <div>{{$t("stake.desc59")}}</div>
          <div>{{$t("stake.desc60")}}</div>
          <div>{{$t("stake.desc61")}}</div>
        </div>
        <div class="table-tbody">
          <div class="tbody-list flex" v-for="item in list" :key="item.id">
            <div class="flexcenter">{{ common.formatDate1(item.sendTime) }}</div>
            <div class="flexcenter">{{ item.receiveTime==null?'--':common.formatDate1(item.receiveTime) }}</div>
            <div class="flexcenter">{{ item.incomeStatus==0?$t('stake.desc38'):$t('stake.desc39') }}</div>
            <div class="flexcenter">{{ item.amount }}</div>
            <div class="flexcenter">{{ item.coinName }}</div>
            <div class="flexcenter">{{ item.incomeType==1?$t("stake.desc41"):(item.incomeType==2?$t("stake.desc42"):(item.incomeType==3?$t("stake.desc44"):$t("stake.desc43"))) }}</div>
            <!-- 操作 -->
            <div class="flex flexcenter green" @click="handlerInfo(item,2)">{{$t("stake.desc62")}}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="table-thead flex">
          <div>{{$t("stake.desc63")}}</div>
          <div>{{$t("stake.desc64")}}</div>
          <div>{{$t("stake.desc65")}}</div>
          <div>{{$t("stake.desc66")}}</div>
          <div>{{$t("stake.desc67")}}</div>
          <div>{{$t("stake.desc68")}}</div>
        </div>
        <div class="table-tbody">
          <div class="tbody-list flex" v-for="item in list" :key="item.id">
            <div class="flexcenter">{{ item.createTime }}</div>
            <div class="flexcenter">{{ item.investAmount }} {{ item.investAmountCoinName}}</div>
            <div class="flexcenter">{{ item.buyBackAmount }} {{item?.compensationAmountCoinName}}</div>
            <div class="flexcenter">{{ item.insuranceState==0?$t('stake.desc69'):item.insuranceState==1?$t('stake.desc70'):$t('stake.desc71') }}</div>
            <div class="flexcenter">{{ item.compensationAmount||'--' }} {{item.compensationAmount?item.compensationAmountCoinName:''}}</div>
            <div class="flexcenter">{{ item.compensationTime||'--' }}</div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
    <div class="rr-h5-time-out animate__animated animate__bounceInUp">
      <div class="rr-h5-time">
        <img src="../../public/images/asset/time-icon.png" />
        <div class="item">
          <div @click="startShow = true"></div>
          <input type="text" v-model="startTime" :placeholder="$t('record.desc1')" disabled />
        </div>
        <span>{{ $t("record.desc2") }}</span>
        <div class="item">
          <div @click="endShow = true"></div>
          <input type="text" v-model="endTime" :placeholder="$t('record.desc3')" disabled />
        </div>
      </div>
      <div class="rr-h5-select">
        <!-- 状态 -->
        <el-select v-if="tabIndex==0" v-model="buyBackState" :placeholder="$t('stake.desc35')" @change="onChage">
          <el-option :value="''" :label="$t('stake.desc35')"></el-option>
          <el-option :value="1" :label="$t('stake.desc36')"></el-option>
          <el-option :value="2" :label="$t('stake.desc37')"></el-option>
        </el-select>
        <!-- 状态 -->
        <el-select  style="margin-left: 0px;" v-if="tabIndex==1" v-model="incomeStatus" :placeholder="$t('stake.desc35')" @change="onChage">
          <el-option :value="''" :label="$t('stake.desc35')"></el-option>
          <el-option :value="0" :label="$t('stake.desc38')"></el-option>
          <el-option :value="1" :label="$t('stake.desc39')"></el-option>
        </el-select>
        <!-- 类型 -->
        <el-select v-if="tabIndex==1" v-model="incomeType" :placeholder="$t('stake.desc40')" @change="onChage">
          <el-option :value="''" :label="$t('stake.desc40')"></el-option>
          <el-option :value="1" :label="$t('stake.desc41')"></el-option>
          <el-option :value="2" :label="$t('stake.desc42')"></el-option>
          <el-option :value="3" :label="$t('stake.desc43')"></el-option>
          <el-option :value="4" :label="$t('stake.desc44')"></el-option>
        </el-select>
        <!-- 产品 -->
        <el-select v-if="tabIndex!=1" v-model="productId" :placeholder="$t('stake.desc45')" @change="onChage">
          <el-option :value="''" :label="$t('stake.desc45')"></el-option>
          <el-option :value="1" :label="'L1'"></el-option>
          <el-option :value="2" :label="'L2'"></el-option>
          <el-option :value="3" :label="'L3'"></el-option>
          <el-option :value="4" :label="'L4'"></el-option>
        </el-select>
      </div>
    </div>
    <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
      <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="pageSize" :current-page="page"
        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
      </el-pagination>
    </div>
    <div class="h5-list">
      <van-list v-model:loading="loading" :loading-text="$t('quotes.desc20')" :finished="finished" @load="onLoad">
        <van-cell v-for="item in list" :key="item.id">
          <div class="h5list-con" v-if="tabIndex==0">
            <div class="h5list-item">
              {{ $t('stake.desc46') }}
              <div>{{ item.effectiveTime||'--' }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc47') }}
              <div>{{ item.createTime }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc48') }}
              <div>{{ item.productName }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc49') }}
              <div>{{ item.maturityTime }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc50') }}
              <div>{{ item.investAmount }} {{item.payCoinName}}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc51') }}
              <div>{{ item.stakedAmount }} USDT</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc52') }}
              <div>{{ item.buyBackState==1?$t('stake.desc36') :$t('stake.desc37')  }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc53') }}
              <div :class="item.buyBackState==1?'green':''" @click="item.buyBackState==1?handlerInfo(item,1):''">{{ $t('stake.desc54') }}</div>
            </div>
          </div>
          <div class="h5list-con" v-if="tabIndex==1">
            <div class="h5list-item">
              {{ $t('stake.desc55') }}
              <div>{{ common.formatDate1(item.sendTime) }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc56') }}
              <div>{{ item.receiveTime==null?'--':common.formatDate1(item.receiveTime) }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc57') }}
              <div>{{ item.incomeStatus==0?$t('stake.desc38'):$t('stake.desc39')}}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc58') }}
              <div>{{ item.amount }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc59') }}
              <div>{{ item.coinName }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc60') }}
              <div>{{ item.incomeType==1?$t("stake.desc41"):(item.incomeType==2?$t("stake.desc42"):(item.incomeType==3?$t("stake.desc44"):$t("stake.desc43"))) }}</div>
            </div>
            <div class="h5list-item" >
              {{ $t('stake.desc61') }}
              <div class="green" @click="handlerInfo(item,2)">{{ $t("stake.desc62") }}</div>
            </div>
          </div>
          <div class="h5list-con" v-if="tabIndex==2">
            <div class="h5list-item">
              {{ $t('stake.desc63') }}
              <div>{{ item.createTime }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc64') }}
              <div>{{ item.investAmount }} {{ item.investAmountCoinName}}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc65') }}
              <div>{{ item.buyBackAmount }} {{item?.compensationAmountCoinName}}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc66') }}
              <div>{{ item.insuranceState==0?$t('stake.desc69'):item.insuranceState==1?$t('stake.desc70'):$t('stake.desc71') }}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc67') }}
              <div>{{ item.compensationAmount||'--' }} {{item.compensationAmount?item.compensationAmountCoinName:''}}</div>
            </div>
            <div class="h5list-item">
              {{ $t('stake.desc68') }}
              <div>{{ item.compensationTime||'--' }}</div>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
    <!-- 领取成功提示 -->
    <el-dialog v-model="showReview">
      <div class="modal-info modal-info-review">
        <div class="info-title">
          <img src="../../public/images/new/closeicon.png" alt="" @click="showReview = false" />
        </div>
        <div class="info-icon">
          <img src="../../public/images/new/success.png" alt="" />
          <div>{{ $t("myincome.desc63") }}</div>
        </div>
        <div class="bt-list">
          <div class="bt"  @click="showReview = false">{{ $t('stake.desc89') }}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 赎回提示 -->
    <el-dialog v-model="showBackBuy">
      <div class="modal-con" v-if="buyBackIndex==1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showBackBuy = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" />
        </div>
        <div class="modal-btncon flexcenter" @click="backBuy()" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd()">{{ $t('sign.desc72') }}</div>
      </div>
      <div class="modal-info modal-info-review" v-if="buyBackIndex==2">
        <div class="info-title">
          <img src="../../public/images/new/closeicon.png" alt="" @click="showBackBuy = false" />
        </div>
        <div class="info-icon">
          <img src="../../public/images/new/success.png" alt="" />
          <div>{{ $t('stake.desc98') }}</div>
        </div>
        <div class="bt-list">
          <div class="bt"  @click="showBackBuy = false">{{$t("common.desc2")}}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 资金密码提示 -->
    <el-dialog v-model="showTradeTip">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTradeTip = false" />
        <div class="modal-title">{{ $t("node.desc45") }}</div>
        <div class="modal-desc">{{ $t("node.desc46") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTradeTip = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(1)">
            {{ $t("node.desc48") }}
          </div>
        </div>
        <div class="forgottitle">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    
    <!-- 详情 -->
    <el-dialog v-model="showDetail" @close="showDetail=false">
      <div class="modal-info modal-info-detail" v-if="showDetail">
        <rwardDetail :detailItem="detailItem" @handlerClose="showDetail=false" />
      </div>
    </el-dialog>
    <!-- 时间插件 -->
    <van-popup v-model:show="startShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="startCurrentTime" :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')" type="date" @confirm="confirmStartFn()" @cancel="startShow = false" />
    </van-popup>
    <van-popup v-model:show="endShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="endCurrentTime" type="date" :confirm-button-text="$t('home.desc20')"
        :cancel-button-text="$t('home.desc45')" @confirm="confirmEndFn()" @cancel="endShow = false" />
    </van-popup>
  </div>
</template>

<script>
import clipboard from "@/utils/libs/clipboard";
import rwardDetail from "@/componets/rwardDetail.vue";
import h5header from "@/componets/h5header.vue";
import psdinput from "@/componets/psdinput.vue";
import bus from "@/utils/bus";
export default {
  data() {
    return {
      showOverview: false,
      startCurrentTime: new Date(),
      endCurrentTime: new Date(),
      value1: "",
      startShow:false,
      endShow: false,
      buyBackIndex:1,
      showBackBuy:false, //赎回
      showTradeTip:false,
      showReview:false, //领取
      password:'',
      detailItem: {},
      statisticsInfo: {},
      statisticsInfoAmountTotal:0,
      incomeTodoInfo: {},
      todoAmountTotal:0,
      userInfo:{},

      tabIndex:0,
      productId:"",
      incomeType: "",
      incomeStatus:"",
      buyBackState:"",
      startTime: "",
      endTime: "",
      list: [],
      page: 1,
      pageSize: 10,
      totals: 0,
      showNull: false,
      showDetail:false,  //奖励的详情
      loading: false,
      finished: false,
      isReviewIng: false, //领取状态
      ismobile:0,
      bonusStart:'',
      bonusEnd:'',
    };
  },
  components: {
    h5header,
    rwardDetail,
    psdinput,
  },
  mounted() {
    this.ismobile = localStorage.getItem('ismobile');
    this.init();
  },
  methods: {
    init() {
      this.getStatistics();
      this.getIncomeTodo();
      if (window.screen.width > 767) {
        this.getlist();
      }
      if (localStorage.getItem("token") != null) {
        this.$post(this.URL.member.info, {}).then((res) => {
          if (res.code == 0) {
            this.userInfo = res.data;
          }
        });
      }
    },
    //获取统计数据
    getStatistics() {
      this.$post(this.URL.stake.statisticsInfo, {}).then((res) => {
        if (res.code == 0) {
          this.statisticsInfo = res.data;
          this.statisticsInfoAmountTotal = Number(res.data.totalRewards) +
            Number(res.data.totalGenerativeRewards) +
            Number(res.data.totalReferralRewards) +
            Number(res.data.totalLevelMatchRewards);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取待领取数据
    getIncomeTodo() {
      this.$post(this.URL.stake.todoInfo, {}).then((res) => {
        if (res.code == 0) {
          this.incomeTodoInfo = res.data;
          this.todoAmountTotal = Number(res.data.stakeRewards) +
            Number(res.data.generativeRewards) +
            Number(res.data.referralRewards) +
            Number(res.data.levelMatchRewards);
          this.incomeTodoInfo.hours = this.getInervalHour(
            this.incomeTodoInfo.lastGetTime,
            this.gettime()
          );
        } else {
          this.$message.error(res.message);
        }
      });
    },
    gettime() {
      return new Date(
        new Date().getTime() +
        (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000
      );
    },
    getInervalHour(startDate, endDate) {
      var ms = endDate.getTime() - startDate * 1000;
      if (ms < 0) return 0;
      return Math.floor(ms / 1000 / 60 / 60);
    },

    //忘记资金密码
    handlerLogpsd() {
      if(localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    //设置资金密码去
    handlerSubmit(i) {
      if(localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", i);
      }
    },

    //一键领取收益
    handelReview() {
      let _this = this;
      // 领取收益
      if (_this.todoAmountTotal <= 0) return;
      if (_this.isReviewIng) return;
      _this.isReviewIng = true;
      _this.$post(_this.URL.stake.claimed, {}).then((res) => {
        _this.isReviewIng = false;
        if (res.code == 0) {
          _this.getIncomeTodo();
          _this.exChangeTab(_this.tabIndex)
          _this.showReview = true;
        } else {
          _this.$message.error(res.message);
        }
      });
      setTimeout(()=>{
        _this.isReviewIng = false;
      },3000)
    },

    finish(val) {
      this.password = val;
    },
    finish1(val) {
      if (val == 0) {
        this.password = "";
      }
    },
    //点击赎回
    backBuy(){
      if (this.password == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      if(this.loading){
        return;
      }
      this.loading = true;
      this.$post(this.URL.stake.buyBack, {orderId:this.detailItem.id,password:this.common.mdpassword(this.password),}).then((res) => {
        if(res.code == 0) {
          this.exChangeTab(2);
          this.buyBackIndex = 2;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //h5加载更多
    onLoad() {
      setTimeout(() => {
        this.getlist();
      }, 500);
    },
    onChage() {
      this.page = 1;
      this.loading = true;
      this.finished = false;
      this.list = [];
      this.getlist();
    },
    confirmStartFn() {
      this.bonusStart = new Date(this.startCurrentTime).getTime();
      
      this.startTime = this.newDate(this.startCurrentTime);
      this.startShow = false;
      this.onChage();
    },
    confirmEndFn() {
      this.bonusEnd = new Date(this.endCurrentTime).getTime();
      this.endTime = this.newDate(this.endCurrentTime);
      this.endShow = false;
      this.onChage();
    },
    //点击赎回 /详情
    handlerInfo(item,type) {
      this.detailItem = item;
      if(type==1){
        if(!this.userInfo.hasSetPassword) {
          this.showTradeTip = true;
          return;
        }
        this.buyBackIndex = 1;
        this.showBackBuy=true;
      }else{
        if(localStorage.getItem('ismobile')==0){
          this.showDetail = true;
        }else{
          // 跳转详情
          this.$router.push({
            path: "/rewarddetailed",
            query: {
              sn: item.sn,
              incomeType: item.incomeType,
            },
          });
        }
      }
    },
    //点击搜索
    handlerSearch() {
      this.list = [];
      this.getlist();
    },
    //重置
    handlerReset() {
      this.page = 1;
      this.pageSize=10;
      this.startTime = "";
      this.endTime = "";
      this.bonusStart = '';
      this.bonusEnd = '';
      this.totals = 0;
      this.value1 = "";
      this.productId = '';
      this.incomeType = '';
      this.incomeStatus = '';
      this.buyBackState = '';
      this.showNull = false;
      this.list = [];
      this.getlist();
    },
    //pc分页
    handlerCurrent(val) {
      this.page = val;
      this.getlist();
    },
    //切换tab列表
    exChangeTab(i) {
      this.tabIndex = i;
      this.handlerReset();
    },
    getlist() {
      let parameter = {};
      let url = '';
      if(this.tabIndex==0){
        url = this.URL.stake.myStakeList;
        parameter = {
          productId:this.productId,
          buyBackState: this.buyBackState, //质押状态  1 未赎回  2 已赎回
          startTime: this.startTime, //起始时间
          endTime: this.endTime, //结束时间
          page: this.page,
          pageSize: this.pageSize,
        }
      }else if(this.tabIndex==1){
        url = this.URL.stake.rewardsList;
        parameter = {
          incomeType:this.incomeType, //1静态奖励，2推荐奖励，3社区奖励，4平级奖励
          incomeStatus:this.incomeStatus, //0待领取，1已领取
          bonusStart:this.bonusStart,
          bonusEnd:this.bonusEnd
        }
      }else{
        url = this.URL.stake.buyBackList;
        parameter = {
          productId:this.productId,
          startTime: this.startTime, //起始时间
          endTime: this.endTime, //结束时间
          page: this.page,
          pageSize: this.pageSize,
        }
      }
      this.showNull = false;
      this.$post(url, parameter).then((res) => {
        if (res.code == 0) {
          if(localStorage.getItem('ismobile')==0){
            this.list = res.data.list;
            this.totals = parseFloat(res.data.page.count);
          }else{
            if (this.page == 1) {
              this.list = res.data.list;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.page++;
            this.loading = false;
            if (this.list.length >= parseFloat(res.data.page.count)) {
              this.finished = true;
            }
          }
          if(this.list.length == 0) {
            this.showNull = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dateSelect(arr) {
      if (arr != null) {
        this.startTime = this.newDate(arr[0]);
        this.endTime = this.newDate(arr[1]);
        this.bonusStart = arr[0];
        this.bonusEnd = arr[1];


      } else {
        this.startTime = "";
        this.endTime = "";
        this.bonusStart = '';
        this.bonusEnd = '';
      }
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    //复制
    copy(e, text) {
      clipboard(e, text);
      this.$message.success(this.$t("planet.desc61"));
    },
  },
};
</script>

<style lang="less" scoped>
.green {
  color: #c9fa5b !important;
}

.mystake {
  max-width: 1240px;
  width: calc(92vw);
  margin: 0 auto;
  padding-top: 132px;
  .mystake-top {
    margin-bottom: 32px;

    .top-info {
      position: relative;
      z-index: 1;
      padding: 28px 32px 32px;
      background: #181818;
      border-radius: 12px;
    }

    .mt-til,
    .mt-til-h5 {
      display: flex;
      align-items: baseline;
      font-family: Bai Jamjuree;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #fff;
      margin-bottom: 24px;

      span {
        display: block;
        font-family: Bai Jamjuree;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 12px;
      }
    }

    .h5top-name {
      display: flex;
      font-size: 24px;
      color: #ffffff;
      padding: 20px 0 0;
      justify-content: space-between;
      line-height: 24px;

      p {
        font-size: 18px;
        color: #a0a0a0;
        line-height: 16px;
        cursor: pointer;

        img {
          width: 12px;
          height: 12px;
          margin: 3px 0 0 6px;
          transform: rotate(180deg);
          transition: 0.5s;
        }

        .img1 {
          transform: rotate(0deg);
          transition: 0.5s;
        }
      }
    }

    .mt-cen {
      display: flex;
      // justify-content: space-between;
      padding-right: 200px;
      flex-wrap: wrap;

      .mt-bt {
        display: none;
      }

      .mt-cen-item {
        flex: 0 0 25%;

        &:nth-child(-n + 8) {
          margin-bottom: 24px;
        }

        .mt-t {
          padding-left: 20px;
          font-family: Bai Jamjuree;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
          margin-bottom: 13px;
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            top: 1px;
            display: block;
            content: "";
            width: 12px;
            height: 12px;
            background: #c9fa5b;
            transform: rotate(45deg);
          }

          img {
            width: 12px;
            height: 12px;
            margin: 1px 8px 0 4px;
          }
        }

        .mt-b {
          font-family: Bai Jamjuree;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
        }

      }
    }

    .mt-bot {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      padding: 28px 32px 32px;
      background: #181818;
      border-radius: 12px;

      .mt-bot-item {
        width: max-content;
        margin-bottom: 20px;

        .mt-t {
          // display: flex;
          // align-items: center;
          font-family: Bai Jamjuree;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0px;
          text-align: left;
          color: #a8a8a8;
          margin-bottom: 8px;
          position: relative;
          z-index: 1;
          .mt-t-name{
            flex: 0 0 90px;
          }
          .mt-t-tips {
            width: 16px;
            height: 16px;
            margin-top: 0;
            margin-left: 2px;
            background: url("../../public/images/asset/my-income/icon.png") no-repeat center;
            background-size: 75% 75%;
            position: relative;
            cursor: pointer;
            z-index: 1;

            span {
              position: absolute;
              top: 16px;
              left: 50%;
              transform: translateX(-50%);
              display: none;
              width: 255px;
              padding: 15px 10px 10px;
              font-family: Bai Jamjuree;
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              color: #fff;
              background: url("../../public/images/asset/my-income/Union.png") no-repeat;
              background-size: 100% 100%;
            }

            &:hover {
              span {
                display: block;
              }
            }
          }
        }

        .mt-b {
          font-family: Bai Jamjuree;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #fff;
        }
      }
    }

    .mt-bt {
      position: absolute;
      top: 40px;
      right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #000;
      width: 173.45px;
      height: 48.68px;
      background: url("../../public/images/asset/my-income/bt.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;

      &.on {
        filter: grayscale(1);
        cursor: inherit;
      }
    }
  }

  .mystake-ex {
    width: 100%;
    display: flex;
    padding: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee33;

    // overflow-x: scroll;
    // &::-webkit-scrollbar {
    //   width: 0px; //修改滚动条宽度
    // }
    .me-item {
      height: 41px;
      font-family: Bai Jamjuree;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      margin-right: 36px;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.6);
      white-space: nowrap;

      &.on {
        color: #fff;
        position: relative;

        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #c9fa5b;
        }
      }
    }
  }

  .mystake-title {
    margin: 40px 0 20px;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
  }

  .mystake-select {
    margin-bottom: 24px;
    justify-content: space-between;

    .select-time {
      flex: 0 0 500px;

      ::v-deep .el-date-editor {
        height: 40px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.2) !important;

        .el-range-input {
          color: #fff;
        }

        .el-range-separator {
          color: #fff;
        }
      }

      ::v-deep .el-select {
        width: 160px;
        margin-left: 20px;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .select-btn {
      flex: 0 0 186px;
      justify-content: space-between;

      div {
        flex: 0 0 88px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 16px;
        color: #c9fa5b;
        cursor: pointer;

        &:last-child {
          background: #c9fa5b;
          color: #000;
          border: 1px solid #c9fa5b;
        }
      }
    }
  }

  .mystake-table {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #181818;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);

      div {
        flex: 1;

        &:last-child {
          flex: 0 0 80px;
          text-align: right;
        }
      }
    }

    .table-tbody {
      .tbody-list {
        padding: 22px 24px;
        font-size: 13px;
        color: #bbbbbb;
        border-bottom: 1px solid #0e0e0e;

        &:hover {
          background: #181818;
        }

        div {
          flex: 1;

          &:last-child {
            flex: 0 0 80px;
            cursor: pointer;
            justify-content: flex-end;

            img {
              width: 10px;
              height: 12px;
              margin: 3px 0 0 4px;
            }
          }
        }

        .flexcenter {
          justify-content: left;
        }
      }
    }

    .no-data {
      width: 100%;
      padding: 130px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
      }
    }
  }

  .speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  .modal-con {
  position: relative;
  padding: 32px;
  width: 480px;
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }
  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }
  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }
  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .modal-input {
    margin: 20px 0 16px;
  }
  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }
  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }
  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
  .forgottitle{
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }
}

  .h5-list {
    display: none;
  }

  .planet-page {
    padding: 0;
    margin-top: 40px;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #c9fa5b !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }

  .rr-h5-time-out {
    display: none;
  }
}

::v-deep .el-dialog {
  // width: 520px;
  width: fit-content !important;

  .modal-info {
    position: relative;
    padding: 40px;
    width: 520px;

    &-review {
      width: 416px;
    }

    &-detail {
      width: 1000px;
    }

    .modal-list {
      margin-top: 32px;

      .list-thead {
        padding: 20px;
        background: #232323;
        justify-content: space-between;

        div {
          flex: 0 0 25%;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.6);

          &:last-child {
            text-align: right;
          }
        }
      }

      .list-tbody {
        padding: 20px;
        font-size: 13px;
        color: #ffffff;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        div {
          flex: 0 0 25%;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .modal-rules {
      margin-top: 20px;
      font-size: 13px;
      color: #e15757;
      line-height: 16px;
    }

    .info-title {
      position: relative;
      font-size: 20px;
      color: #fff;

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .info-icon {
      margin: 16px 0;
      text-align: center;
      font-size: 18px;
      color: #ffffff;

      img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
    }

    .info-tips {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 32px;
      letter-spacing: 0.05em;

      span {
        color: #c9fa5b;
      }
    }

    .bt-list {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 40px;

      .bt {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 52px;
        background: url("../../public/images/asset/my-income/bt-1.png") no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;

        &:last-child {
          color: #000;
          background: url("../../public/images/asset/my-income/bt-2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .info-list {
      padding: 20px;
      border-radius: 8px;
      background: #242424;

      .list-item {
        margin-bottom: 16px;
        font-size: 16px;

        &:nth-child(6) {
          padding-top: 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        div {
          flex: 0 0 30%;

          color: rgba(255, 255, 255, 0.6);
        }

        p {
          flex: 0 0 70%;
          text-align: right;
          color: #fff;
          justify-content: flex-end;

          img {
            width: 12px;
            height: 12px;
            margin: 4px 0 0 8px;
            cursor: pointer;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .mystake {
    .mystake-select {
      .select-time {
        flex: 0 0 440px;

        ::v-deep .el-date-editor {
          width: 260px;
        }
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }

}

@media (max-width: 767px) {
  .mystake {
    padding-top: 56px;
    .mystake-top {
      padding: 0;
      background: transparent;
      margin-bottom: 20px;

      .top-info {
        padding: 16px 12px;

      }

      .mt-cen {
        margin-bottom: 0 !important;
        padding: 16px 0 !important;
      }

      .mt-til {
        display: block;
        font-size: 16px;
        margin-bottom: 12px;

        span {
          font-size: 12px;
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
        }
      }

      .mt-bt {
        display: none;
      }

      .h5top-name {
        font-size: 16px;
        line-height: 16px;

        p {
          font-size: 14px;

          img {
            margin: 1px 0 0 6px;
          }
        }
      }

      .mt-cen {
        background: #181818;
        flex-wrap: wrap;
        padding: 20px;
        gap: 0;
        margin-bottom: 20px;
        border-radius: 12px;

        .mt-cen-item {
          flex: 0 0 50%;
          margin: 0;
          padding: 0;

          &:nth-child(-n + 8) {
            margin-bottom: 16px;
          }

          .mt-t {
            font-size: 12px;
          }

          .mt-b {
            font-size: 18px;
          }
        }

        .mt-bt {
          margin: 0 auto;
          position: inherit;
          top: inherit;
          right: inherit;
          width: 311px;
          height: 40px;
          display: flex;
          background: url("../../public/images/asset/my-income/bt-3.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .mt-til-h5 {
        display: block;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
      }

      .mt-bot {
        display: flex;
        flex-wrap: wrap;
        background: #181818;
        border-radius: 12px;
        padding: 20px;
        gap: 20px;
        flex-wrap: wrap;

        .mt-bot-item {
          width: calc(50% - 10px);
          margin: 0;
        }
      }
    }

    .mystake-ex {
      margin-bottom: 18px;
      padding: 0;

      .me-item {
        font-size: 14px;
        height: 30px;
      }
    }

    .mystake-title {
      margin: 0 0 16px;
      font-size: 16px;
    }

    .mystake-select {
      display: none;
    }

    .mystake-btn {
      margin: 24px 0 0;
      display: flex;
      justify-content: space-between;

      div {
        flex: 0 0 48%;
        height: 36px;

        font-size: 16px;
        background: url("../../public/images/new/btnbg17.png") center no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }

      .btn1 {
        background: url("../../public/images/new/btnbg16.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }

    .mystake-table {
      display: none;
    }

    .rr-h5-time-out {
      display: block;
    }

    .rr-h5-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;
      height: 48px;
      padding: 0 44px 0 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;

      &-out {
        display: block;
        padding: 0;
        animation-delay: 0.3s;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }

      span {
        display: block;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        padding: 0 18px;
        width: 80px;
        text-align: center;
      }

      input {
        width: 100%;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;

        &::placeholder {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          text-transform: capitalize;
          color: rgba(255, 255, 255, 0.3);
        }
      }

      .item {
        position: relative;

        div {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 22;
          width: 100%;
          height: 100%;
        }
      }
    }

    .rr-h5-select {
      ::v-deep .el-select {
        width: 100%;
        margin-bottom: 11px;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .h5-list {
      margin-top: 16px;
      display: block;
      padding: 0;
      border-radius: 8px;
      background: none;

      ::v-deep .van-list {
        .van-cell {
          background: #181818;
          padding: 16px;
          margin-bottom: 10px;
          border-radius: 8px;

          &::after {
            left: 0;
            border-bottom: none;
          }
        }

        .h5list-con {
          .h5list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 14px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 13px;
            font-weight: 400;

            &:last-child {
              padding-bottom: 0;
            }

            div {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              text-align: right;
              .speed{
                margin-top:10px;
              }
            }
          }
        }
      }

      .no-data {
        width: 100%;
        padding: 40px 0;

        img {
          display: block;
          width: 120px;
          height: 120px;
          margin: 0 auto 16px;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #a1a1a1;
        }
      }
    }

    .planet-page {
      display: none;
    }
  }

  ::v-deep .el-dialog {
    .modal-info-detail {
      width: calc(100vw - 8px);
    }

    .modal-info {
      padding: 16px;

      .modal-list {
        .list-thead {
          padding: 12px;
        }

        .list-tbody {
          padding: 12px;
        }
      }

      &-review {
        width: 327px;
      }

      .info-icon {
        margin: 16px 0;
        font-size: 16px;
      }

      .info-list {
        padding: 12px;

        .list-item {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}</style>
