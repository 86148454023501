<template>
  <div class="detail">
    <div class="title">{{ $t('stake.desc100') }}
      <img src="../public/images/new/closeicon.png" alt="" @click="handlerClose" />
    </div>
    <div class="wrap">
      <div class="wrap-til">{{ $t('stake.desc73') }}:<span style="margin-left: 4px">{{ info.earningDate }}</span></div>
      <!-- <div class="wrap-til" v-if="detailItem.incomeType==1">{{ $t('stake.desc26') }}:<span style="margin-left: 4px">{{ common.cutXiaoNum1(info.investTotalAmount) }} {{info.investCoinName}}</span></div> -->
      <div class="wrap-til">{{ $t('stake.desc74') }}:<span style="margin-left: 4px">{{ common.cutXiaoNum1(info.earningTotalAmount) }} {{info.earningCoinName}}</span></div>
      
    </div>
    <div class="myincome-table animate__animated animate__fadeInUp animate__delay-.6s">
      <div class="table-thead flex">
        <!-- 质押数量 --- 来源用户 -->
        <div>{{detailItem.incomeType==1?$t('stake.desc76'):$t('stake.desc103')}}</div> 
        <!-- 来源奖励数量  -->
        <div v-if="detailItem.incomeType!=1">{{ $t('stake.desc78') }}</div>
        <!-- 利率 收益率-->
        <div>{{ $t('stake.desc93') }}</div>
        <!-- 产品名称 -->
        <div v-if="detailItem.incomeType==1">{{$t('stake.desc48')}}</div>
        <!-- 浮动利率 -->
        <div v-if="detailItem.incomeType==1">{{$t('stake.desc101')}}</div>
        <!-- 奖励类型 -->
        <div>{{$t('stake.desc60')}}</div>
        <!-- 奖励数量 -->
        <div>{{$t('stake.desc77')}}</div>
      </div>
      <div class="table-tbody">
        <div class="tbody-list" v-for="item in info.voList" :key="item.id">
          <div v-if="detailItem.incomeType==1">{{ item.investTotalAmount }} {{ item.investCoinName }}</div>
          <div v-else>{{ item.resourceUserId }}</div>
          <div v-if="detailItem.incomeType!=1">{{ item.resourceEarningTotalAmount }} {{ item.earningCoinName }}</div>
          <div>{{ common.getValueMultip(item.earningRate, 100) }}%</div>
          <div v-if="detailItem.incomeType==1">{{ item.productName}}</div>
          <div v-if="detailItem.incomeType==1">{{ common.getValueMultip(item.earningRateAddition,100)}}%</div>
          <div>{{ item.earningType==1?$t("stake.desc41"):(item.earningType==2?$t("stake.desc42"):(item.earningType==3?$t("stake.desc44"):$t("stake.desc43"))) }}</div>
          <div>{{ item.earningAmount }} {{ item.earningCoinName }}</div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../public/images/no-data.png" alt="">
        <span>{{ $t('record.desc13') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from "@/store";
export default {
  computed: {
    ...mapState(['assetType']),
  },
  props: ['detailItem'],
  data() {
    return {
      info: {},
      showNull: false
    }
  },
  mounted() {
    this.handlerInfo(this.detailItem)
  },
  methods: {
    handlerClose() {
      this.$emit('handlerClose')
    },
    handlerInfo(item) {
      let url = this.URL.stake.rewardsDetail
      this.$post(url, {
        sn: item.sn,
        earningType:item.incomeType
      }).then(res => {
        if(res.code == 0) {
          this.info = res.data;
          if (this.info.voList.length == 0) {
            this.showNull = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  .title {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
    img{
      position: absolute;
      top: 2px;
      right: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .wrap {
    display: flex;
    align-items: center;

    .wrap-til {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 40px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);

      span {
        color: #fff;
        &.on {
          color: #C9FA5B;
        }
      }
    }
  }

  .myincome-table {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #232323;
      font-size: 13px;
      color: rgba(255, 255, 255, .6);
      display: flex;
      justify-content: space-between;
      div {
        min-width: 15%;
        text-align: left;
        &:last-child {
          text-align: right;
        }
      }
    }

    .table-tbody {
      max-height: 590px;
      overflow-y: scroll;

      &::-webkit-scrollbar {

        display: none;

      }

      .tbody-list {
        padding: 22px 24px;
        font-size: 13px;
        color: #BBBBBB;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        display: flex;
        justify-content: space-between;
        &:hover {
          background: #181818;
        }

        div {
          width: fit-content;
          min-width: 15%;
          text-align: left;
          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .no-data {
      width: 100%;
      padding: 100px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #A1A1A1;
      }
    }
  }
  .tips{
    margin-top: 20px;
      font-size: 13px;
      color: #e15757;
      line-height: 16px;
  }
}

.speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  @media only screen and  (max-width: 768px){
    .detail {
      .myincome-table {
        .table-thead {
          padding: 12px;
        }
      }
    }
    
    
  }

</style>
